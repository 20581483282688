<template>
  <div class="full-width full-height bg-blue  text-lightblue p-md">
    <vue-scroll :ops="$root.scrollOpsY" style="height:100%">
      <back url="/resource/index" />
      <div class="full-con m-t-sm">
        <div class="cameral-join-count m-b-sm">
          <div class="hd flex items-center justify-between text-white">
            <span class="before-line relative p-w-md flex items-center"
              >摄像机接入量：133,452<i class="icon-undo m-l-md pointer"></i
            ></span>
            <div>
              <a-select
                default-value="按省份"
                style="width: 140px"
                @change="handleChange"
                size="small"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="chart-hd"><stack-chart /></div>
        </div>
        <div class="cameral-run-info m-b-sm flex">
          <div class="flex-one">
            <div class="hd text-white">
              <span class="before-line relative p-w-md"
                >摄像机运行状况统计</span
              >
            </div>
            <div class="full-width hd2">
              <chart-bar-run-com el="chart4" :data="runStatis" />
            </div>
          </div>
          <div class="flex-one">
            <div class="hd text-white">
              <span class="before-line relative p-w-md">摄像机接入率统计</span>
            </div>
            <div class="full-width hd2">
              <chart-bar-run-com el="chart5" :data="cameralRate" />
            </div>
          </div>
          <div class="flex-one">
            <div class="hd text-white">
              <span class="before-line relative p-w-md">摄像机类型统计</span>
            </div>
            <div class="full-width hd2">
              <chart-bar-run-com el="chart6" :data="cameralType" />
            </div>
          </div>
        </div>
        <div class="cameral-list m-b-sm">
          <div class="hd flex items-center justify-between">
            <span class="before-line text-white p-w-md relative"
              >摄像机列表</span
            >
            <div class="flex items-center">
              <a-select
                placeholder="请选择省份"
                style="width: 140px"
                @change="handleChange"
                size="small"
                class="m-r-xs"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
              <a-select
                placeholder="请选择管辖单位"
                style="width: 140px"
                @change="handleChange"
                size="small"
                class="m-r-xs"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
              <a-select
                placeholder="请选择所属路线"
                style="width: 140px"
                @change="handleChange"
                size="small"
                class="m-r-xs"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
              <a-select
                placeholder="请选择所属类别"
                style="width: 140px"
                @change="handleChange"
                size="small"
                class="m-r-xs"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
              <a-select
                placeholder="请选择摄像机状态"
                style="width: 140px"
                @change="handleChange"
                size="small"
                class="m-r-xs"
              >
                <a-select-option value="按省份">
                  按省份
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
              <a-button type="primary" size="small" class="m-r-sm btn-search">
                搜索
              </a-button>
              <a-button class="btn-reset" size="small">
                重置
              </a-button>
              <span class="span-line m-l-md m-r-md"></span>
              <a-button class="btn-export" size="small">
                批量下载
              </a-button>
            </div>
          </div>
          <div class="hd3 m-t-sm">
            <div class="relative full-width">
              <a-table
                :row-selection="{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange,
                }"
                :columns="columns"
                :data-source="data"
                :pagination="paginationOpt"
                :scroll="{ x: 'calc(700px + 50%)' }"
                bordered
              >
                <span
                  slot="customStatus"
                  slot-scope="text"
                  style="color: #4271AA"
                >
                  {{ text }}
                </span>
              </a-table>
              <span class="absolute l0 b20 text-lightblue">共有90条</span>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import Back from '@/components/Back';
import StackChart from '@/components/StackChart';
import ChartBarRunCom from '@/components/ChartBarRunCom';
export default {
  name: 'CameralStatistic',
  data() {
    return {
      runStatis: {
        name: '总数',
        count: '11,8505',
        showTitle: true,
      },
      cameralRate: {
        name: '已接入',
        count: '80%',
        showTitle: true,
      },
      cameralType: {
        name: '',
        count: '',
        showTitle: false,
      },
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 20, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          // this.searchCameraFrom(); //显示列表的接口名称
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          // this.searchCameraFrom();
          // this.searchCameraFromTest();
        },
      },
      columns: [
        {
          title: '排序',
          customRender: (text, record, index) =>
            `${(this.paginationOpt.current - 1) *
              this.paginationOpt.defaultPageSize +
              index +
              1}`,
          width: 80,
        },
        {
          title: '地区',
          dataIndex: 'area',
          key: 'area',
          width: 200,
          // ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organization',
          key: 'organization',
          width: 120,
          // ellipsis: true,
        },
        {
          title: '所属路线',
          dataIndex: 'route',
          key: 'route',
          width: 120,
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameralName',
          key: 'cameralName',
          width: 220,
          // ellipsis: true,
        },
        {
          title: '桩号',
          dataIndex: 'kmile',
          key: 'kmile',
          width: 120,
        },
        {
          title: '所属类别',
          dataIndex: 'category',
          key: 'category',
          width: 120,
        },
        {
          title: '方向',
          dataIndex: 'direction',
          key: 'direction',
          width: 100,
        },
        {
          title: '摄像机状态',
          dataIndex: 'status',
          key: 'status',
          width: 100,
          scopedSlots: { customRender: 'customStatus' },
          // ellipsis: true,
        },
        /* {
          title: '兴趣点名称',
          dataIndex: 'potName',
          key: 'potName',
          width: 160,
          // ellipsis: true,
        }, */
      ],
      data: [
        {
          key: 0,
          area: '浙江省/衢州市/江山区',
          organization: 'grass',
          route: 'G3京台高速',
          kmile: 'k1518+750',
          /* potName: '九华山隧道', */
          category: '道路沿线',
          direction: '上行',
          status: '正常',
        },
      ],
      selectedRowKeys: [],
    };
  },
  components: {
    Back,
    StackChart,
    ChartBarRunCom,
  },
  computed: {},
  watch: {},
  methods: {
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<style scoped>
.chart-hd {
  height: 180px;
}
.hd2 {
  height: 140px;
}
</style>
